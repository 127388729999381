import { useQuery } from "@apollo/client";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Button } from "react-bootstrap";
import { BLOG_LIST } from "../../../action/blog";
import { useRouter } from "next/router";

const BlogSection = ({ data }) => {
  const { t } = useTranslation("home");
  const router = useRouter();

  const { loading, error, data: dataBlog } = useQuery(BLOG_LIST);

  const BlogData = dataBlog?.blog?.posts;

  const handleCategoryClick = (categoryName) => {
    const formattedCategory = categoryName
      .toLowerCase()
      .replace("&", "dan")
      .split(" ")
      .join("-");
    router.push({
      pathname: "/blog",
      query: { category: formattedCategory },
    });
  };

  return (
    <div
      className="position-relative"
      style={{ marginTop: "9.375em", marginBottom: "6.25em" }}
    >
      <div style={{ opacity: "10%" }}>
        <Image
          src="/assets/city-property-background.png"
          layout="fill"
          objectFit="cover"
        />
      </div>
      <div className="position-relative container d-flex flex-column gap-4">
        <h2 className="card-title ps-2 font-roboto fw-bolder border border-black border-5 border-top-0 border-bottom-0 border-end-0">
          {t("article.title")}
        </h2>
        <h3
          className="card-title mb-0 fs-5 fw-normal"
          style={{ color: "#606060" }}
        >
          {t("article.description")}
        </h3>

        <div className="row g-4">
          <div className="col-12 col-md-6">
            {BlogData?.slice(0, 1).map((blog) => (
              <div
                className="position-relative rounded-2 overflow-hidden w-100 img-lazy"
                style={{ height: "400px" }}
              >
                <Image
                  src={blog.thumbnail || "/assets/no-image.png"}
                  alt={blog.post_title}
                  layout="fill"
                  placeholder="blur"
                  blurDataURL={blog.thumbnail || "/assets/no-image.png"}
                  objectFit="cover"
                  unoptimized
                />

                <div className="bg-dark bg-opacity-50 cursor-pointer position-absolute d-flex flex-column gap-2 px-4 py-2 bottom-0 text-white">
                  <div
                    className="cursor-pointer d-flex"
                    onClick={() => handleCategoryClick(blog.category)}
                  >
                    <div className="bg-info mw-100 p-2 small-secondary-text">
                      {blog.category}
                    </div>
                  </div>

                  <Link href={`/blog/${blog.post_name}`}>
                    <h5 className="fs-5 fw-bold">{blog.post_title}</h5>
                  </Link>

                  <div className="d-flex gap-2 small">
                    <i className="bi bi-clock"></i>

                    <p>
                      {new Date(blog.post_date).toLocaleString("id", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      })}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className="col-12 col-md-6 d-flex flex-column align-items-center"
            style={{ gap: "20px" }}
          >
            {BlogData?.slice(1, 4).map((blog, i) => (
              <div key={i} className="row w-100 align-items-md-center">
                <div
                  className="col-4 position-relative rounded-2 overflow-hidden img-lazy"
                  style={{
                    height: "120px",
                  }}
                >
                  <Image
                    src={blog.thumbnail || "/assets/no-image.png"}
                    alt={blog.post_title}
                    layout="fill"
                    placeholder="blur"
                    blurDataURL={blog.thumbnail || "/assets/no-image.png"}
                    objectFit="cover"
                    unoptimized
                  />
                </div>

                <div className="col-8 d-flex flex-column text-secondary justify-content-between h-100">
                  <div>
                    <div
                      className="cursor-pointer ps-1 border-top-0 border-bottom-0 border-end-0"
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "#006EA7",
                        borderColor: "#006EA7",
                        borderStyle: "solid",
                        borderWidth: "2px",
                      }}
                      onClick={() => handleCategoryClick(blog?.category)}
                    >
                      {blog.category}
                    </div>

                    <h5
                      className="two-line-truncate"
                      style={{
                        color: "#21314A",
                        fontSize: "20px",
                        fontWeight: 500,
                      }}
                    >
                      {blog.post_title}
                    </h5>
                  </div>

                  <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div
                      className="d-flex gap-2 p-0 align-items-center"
                      style={{ color: "#606060" }}
                    >
                      <i
                        className="bi bi-clock"
                        style={{ fontSize: "11px" }}
                      ></i>

                      <p
                        className="mb-0 p-0"
                        style={{ fontSize: "15px", fontWeight: 400 }}
                      >
                        {new Date(blog.post_date).toLocaleString("id", {
                          year: "numeric",
                          month: "long",
                          day: "2-digit",
                        })}
                      </p>
                    </div>

                    <Link href={`/blog/${blog.post_name}`}>
                      <p className="cursor-pointer text-primary small-secondary-text mb-0 p-0">
                        {t("article.see more")} &gt; &gt;
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Link href="/blog">
          <Button
            variant="outline-primary"
            className="rounded-5 border-2"
            style={{ fontWeight: 500 }}
          >
            {t("article.see all")} &gt;&gt;
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default BlogSection;
