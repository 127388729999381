import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import Link from "next/link";
import Image from "next/image";
import { useLazyQuery } from "@apollo/client";
import { Dropdown } from "react-bootstrap";
import { useStateContext } from "../../../context";
import { useCookie } from "next-cookie";
import { TOKEN_REDIRECT_PANEL } from "../../../action/user";
import { VerticalLineIcon } from "../Icon";
import useTranslation from "next-translate/useTranslation";
import setLanguage from "next-translate/setLanguage";

const Button = dynamic(() => import("react-bootstrap/Button"), { ssr: false });
const MenuModal = dynamic(() => import("./MenuModal"), { ssr: false });
const SearchModal = dynamic(() => import("./SearchModal"), { ssr: false });
const MenuIcon = dynamic(() => import("@heroicons/react/solid/MenuIcon"), {
  ssr: false,
});

const HeaderSearch = (props) => {
  const { isLanding } = props;
  const { t } = useTranslation("common");
  const router = useRouter();
  const token = useCookie().get("token");
  const { search } = router.query;
  const pathname = router.pathname;
  const selectedPathname = pathname.split("/")[1];
  const [state, setState] = useStateContext();
  const [searchInputValue, setSearchInputValue] = useState(search || "");
  const [suggestionModalOpen, setSuggestionModalOpen] = useState(false);
  const [menuModalOpen, setMenuModalOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");

  useEffect(() => storePathValues, [router.asPath]);

  const [getToken] = useLazyQuery(TOKEN_REDIRECT_PANEL, {
    onCompleted: (resp) => {
      const baseUrl = process.env.NEXT_PUBLIC_BASE_ADMIN_URL;
      const redirectUrl = `${baseUrl}/masuk/${resp.token_redirect_panel}?page=${selectedMenu}`;
      console.log(redirectUrl);
      window.open(redirectUrl);
    },
    onError: (resp) => {
      if (resp.message === "Unauthenticated") {
        window.open(
          `${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/${selectedMenu}`
        );
      }
    },
    fetchPolicy: "no-cache",
  });

  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem("currentPath");
    storage.setItem("prevPath", prevPath);
    // Set the current path value by looking at the browser's location object.
    storage.setItem("currentPath", globalThis.location.pathname);
  }

  function handleLoginClick() {
    TRACK_LOGIN_SELECTED();

    setState({ ...state, loginModal: true });
  }

  const handleToggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleClickOutsideDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (showDropdown && !e.target.closest(".dropdown-menu")) {
        handleClickOutsideDropdown();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showDropdown]);

  const handleRedirecting = (item) => {
    setSelectedMenu(item);
    getToken();
  };

  const user = state.user;
  const isLoginPage = router.pathname === "/login";
  const isAgentPage = router.pathname.split("/")[1] === "agent";

  const menuList = [
    { title: t("header_menu.title"), link: "/", line: true },
    { title: "Project Baru", link: "/project-baru", line: true },
    { title: "Blog", link: "/blog", line: true },
    { title: t("header_menu.package"), link: "/packages", line: true },
    { title: t("header_menu.terms"), link: "/term-policy", line: false },
  ];

  return (
    <>
      <header>
        <div className={`px-0 py-0 bg-white shadow-sm position-relative`}>
          <div
            className={`position-absolute d-none d-lg-flex end-0 bottom-0 bg-menu`}
            style={{ backgroundColor: "#5375B1", width: "70%", height: "65px" }}
          ></div>
          <div
            className={`container d-flex flex-row align-items-center justify-content-between`}
          >
            <Link href="/">
              <a>
                {isAgentPage ? (
                  <Image
                    src="/logo_rmd.png"
                    alt="logo rumahdewi"
                    width="200px"
                    height="60px"
                    quality={100}
                    placeholder="blur"
                    blurDataURL="/logo_rmd.png"
                  />
                ) : (
                  <Image
                    src="/logo_rmd_blue.png"
                    alt="logo rumahdewi"
                    width="200px"
                    height="60px"
                    quality={100}
                    placeholder="blur"
                    blurDataURL="/logo_rmd_blue.png"
                  />
                )}
              </a>
            </Link>

            <div className="d-flex flex-column">
              <div
                className="d-none d-lg-flex flex-row justify-content-between w-100"
                style={{ paddingBottom: "5px" }}
              >
                <div className="d-flex gap-3 align-items-center">
                  <p
                    className="mb-0 align-self-center"
                    style={{ fontSize: "13px", color: "#909090" }}
                  >
                    {t("header_menu.follow us")} :
                  </p>

                  <Link
                    href={`https://www.facebook.com/rumahdewicom?mibextid=ZbWKwL`}
                  >
                    <a target="_blank" className="footer-logo">
                      <i
                        className="bi bi-facebook"
                        style={{ color: "#909090" }}
                      />
                    </a>
                  </Link>

                  {/* <Link href={`https://x.com/rumahdewionline`}>
                    <a target="_blank" className="footer-logo">
                      <i
                        className="bi bi-twitter-x"
                        style={{ color: "#909090" }}
                      />
                    </a>
                  </Link> */}

                  <Link
                    href={`https://www.instagram.com/rumahdewi.ig?igsh=MTVlYzRuN3M0cm90Yg==`}
                  >
                    <a target="_blank" className="footer-logo">
                      <i
                        className="bi bi-instagram"
                        style={{ color: "#909090" }}
                      />
                    </a>
                  </Link>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <p
                    className="m-0"
                    style={{ color: "#606060", fontSize: "13px" }}
                  >
                    {t("language")}:
                  </p>
                  <select
                    value={router.locale}
                    onChange={(e) => setLanguage(e.target.value)}
                    className="p-1 bg-transparent border-0"
                  >
                    {router.locales?.map((locale, i) => (
                      <option key={i} value={locale}>
                        {locale.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="d-flex gap-4 align-items-center justify-content-between">
                <div
                  className={`d-none d-lg-flex align-items-center position-relative header-menu-gap`}
                >
                  {menuList.map((menu, i) => (
                    <div
                      key={i}
                      className="d-none d-lg-flex flex-row align-items-center header-menu-gap"
                      style={{ height: "65px" }}
                    >
                      <Link href={menu.link}>
                        <a
                          className={`d-flex align-items-center position-relative menu-color justify-content-center`}
                          style={{
                            height: "65px",
                            backgroundColor:
                              `/${selectedPathname}` === menu.link
                                ? "#FFFFFF10"
                                : "#FFFFFF00",
                            borderBottom:
                              `/${selectedPathname}` === menu.link
                                ? "3px solid white"
                                : "",
                          }}
                        >
                          <Button
                            variant="link"
                            className={`fw-medium p-0 text-decoration-none `}
                            style={{ fontSize: "15px", color: "#C9DCFF" }}
                          >
                            {menu.title}
                          </Button>
                        </a>
                      </Link>

                      {menu.line === true && <VerticalLineIcon />}
                    </div>
                  ))}

                  {!isLoginPage ? (
                    !!user && token ? (
                      <div className="align-items-center">
                        {showDropdown && (
                          <div
                            className="overlay position-fixed top-0 start-0 w-100 h-100"
                            style={{
                              zIndex: 9998,
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                            }}
                            onClick={handleClickOutsideDropdown}
                          />
                        )}

                        <Dropdown
                          align="end"
                          show={showDropdown}
                          className="align-items-center"
                        >
                          <Dropdown.Toggle
                            className="d-flex align-items-center p-2"
                            style={{
                              border: "none",
                              fontSize: "15px",
                              color: "#21314A",
                              fontWeight: "500",
                              zIndex: 9999,
                              backgroundColor: showDropdown
                                ? "#d9d9d9"
                                : "transparent",
                              height: "30px",
                            }}
                            onClick={handleToggleDropdown}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{}}
                            >
                              <div
                                className="rounded-circle bg-black d-flex align-items-center justify-content-center position-absolute"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  marginRight: "10px",
                                  border: "2px solid white",
                                  transform: "translateX(-20%)",
                                  zIndex: 9999,
                                }}
                              >
                                {user.avatar ? (
                                  <Image
                                    src={user.avatar}
                                    width={24}
                                    height={24}
                                  />
                                ) : (
                                  <i
                                    className="bi bi-person-fill text-white"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                )}
                              </div>
                              <div
                                className={`w-100 position-relative fw-medium text-decoration-none 
                        ${
                          isAgentPage || showDropdown
                            ? "text-white"
                            : "text-secondary"
                        }`}
                                style={{
                                  paddingLeft: "50px",
                                  paddingRight: "10px",
                                  zIndex: 9999,
                                }}
                              >
                                {user.first_name} {user.last_name}
                              </div>
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            className="mt-2 d-flex flex-column gap-3"
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              borderRadius: "10px",
                              zIndex: 9999,
                            }}
                          >
                            <Dropdown.Item>
                              <span
                                className="d-flex align-items-center gap-2 justify-content-between"
                                onClick={() => handleRedirecting("profile")}
                                style={{ color: "#606060" }}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src="/assets/profile.svg"
                                    width="20px"
                                    height="20px"
                                    style={{ marginBottom: ".1rem" }}
                                  />{" "}
                                  {t("header_menu.my profile")}
                                </div>
                                <i className="bi bi-chevron-right"></i>
                              </span>
                            </Dropdown.Item>

                            <Dropdown.Item>
                              <span
                                className="d-flex align-items-center gap-2 justify-content-between"
                                onClick={() => handleRedirecting("listing")}
                                style={{ color: "#606060" }}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src="/assets/listing.svg"
                                    width="20px"
                                    height="20px"
                                    style={{ marginBottom: ".1rem" }}
                                  />
                                  {t("header_menu.my listing")}
                                </div>
                                <i className="bi bi-chevron-right"></i>
                              </span>
                            </Dropdown.Item>

                            <Dropdown.Item>
                              <Link href="/wishlist">
                                <a
                                  className="d-flex align-items-center gap-2 justify-content-between"
                                  style={{ color: "#606060" }}
                                >
                                  <div className="d-flex align-items-center gap-2">
                                    <img
                                      src="/assets/heart.svg"
                                      width="20px"
                                      height="20px"
                                      style={{ marginBottom: ".1rem" }}
                                    />
                                    Wishlist
                                  </div>
                                  <i className="bi bi-chevron-right"></i>
                                </a>
                              </Link>
                            </Dropdown.Item>

                            <Dropdown.Item>
                              <Link href="/logout">
                                <a
                                  className="d-flex align-items-center gap-2"
                                  style={{ color: "#D10000" }}
                                >
                                  <img
                                    src="/assets/logout.svg"
                                    width="20px"
                                    height="20px"
                                    style={{ marginBottom: ".1rem" }}
                                  />
                                  {t("header_menu.logout")}
                                </a>
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      <Button
                        style={{ backgroundColor: "#FF9A01" }}
                        className="rounded posiiton-relative"
                        onClick={() => router.push("/login")}
                      >
                        <div className="d-flex gap-1 gap-xl-2 position-relative px-xl-3">
                          <i className="bi bi-box-arrow-in-left"></i>
                          <p className="mb-0">{t("header_menu.login")}</p>
                        </div>
                      </Button>
                    )
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-lg-none">
                  <select
                    value={router.locale}
                    onChange={(e) => setLanguage(e.target.value)}
                    className="p-1 bg-transparent	border-0"
                  >
                    {router.locales?.map((locale, i) => (
                      <option key={i} value={locale}>
                        {locale.toUpperCase()}
                      </option>
                    ))}
                  </select>

                  <Button
                    onClick={() => setMenuModalOpen(true)}
                    variant="link"
                    className={`d-lg-none z-100 position-relative ${
                      isAgentPage ? "text-white" : ""
                    }`}
                  >
                    <MenuIcon width="25px" height="25px" />
                  </Button>

                  {suggestionModalOpen && (
                    <SearchModal
                      currentInput={searchInputValue}
                      setCurrentInputSearchValue={setSearchInputValue}
                      modalOpenStatus={suggestionModalOpen}
                      setModalOpenStatus={setSuggestionModalOpen}
                    />
                  )}

                  {menuModalOpen && (
                    <MenuModal
                      modalOpenStatus={menuModalOpen}
                      setModalOpenStatus={setMenuModalOpen}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderSearch;

{
  /* <div>
            
            
            <div className="container">
              <div>
                
              </div>
              
            </div>

            
          </div>
        </div> */
}
