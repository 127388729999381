import { useLazyQuery, useQuery } from "@apollo/client";
import { TRACK_ADD_LISTING } from "../../utils/trackEvent";
import { TOKEN_REDIRECT_PANEL } from "../../action/user";
import { useState } from "react";
import Image from "next/image";
import { Carousel } from "react-bootstrap";
import useTranslation from "next-translate/useTranslation";
import { BANNER_LIST } from "../../action/banner";
import Link from "next/link";

export default function BannerSlider() {
  const { t } = useTranslation("common");
  const [activeIndex, setActiveIndex] = useState(0);
  const [getToken] = useLazyQuery(TOKEN_REDIRECT_PANEL, {
    onCompleted: (resp) => {
      window.open(
        `${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/masuk/${resp.token_redirect_panel}`
      );
    },
    onError: (resp) => {
      if (resp.message === "Unauthenticated") {
        window.open(`${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/listing-saya`);
      }
    },
  });

  const { loading, error, data } = useQuery(BANNER_LIST);

  function onAddListingClick() {
    TRACK_ADD_LISTING();
    getToken();
  }

  const dataBanner = data?.banner?.banners;

  return (
    <div className="position-relative">
      <div
        className="position-absolute"
        style={{
          width: "187px",
          height: "181px",
          top: -120,
          left: -10,
          zIndex: -1,
        }}
      >
        <Image
          src="/assets/banner-slider-background.png"
          layout="fill"
          alt="banner item"
        />
      </div>

      <div
        className="position-relative container d-flex flex-column align-items-center gap-2"
        style={{ marginBlock: "6.25em" }}
      >
        <div className="w-100">
          <Carousel
            activeIndex={activeIndex}
            onSelect={(selectedIndex) => setActiveIndex(selectedIndex)}
            touch={true}
            controls={false}
            indicators={false}
          >
            {dataBanner?.map((banner) => (
              <Carousel.Item key={banner.id}>
                <Link href={banner.url}>
                  <div className="align-content-center position-relative card-banner-slider">
                    <Image
                      src={banner.media_image.default}
                      alt="listing recommendation"
                      placeholder="blur"
                      blurDataURL={banner.media_image.default}
                      className="card"
                      layout="fill"
                    />

                    <div className="position-relative card-body align-content-center p-3 m-3">
                      <div>
                        <h2 className="mb-4 text-white">{banner.name}</h2>
                        {/* <button
                          onClick={onAddListingClick}
                          className="d-none d-md-block btn btn-warning text-white py-2 px-5 text-decoration-none"
                          target="_blank"
                        >
                          {t("add your listing")}
                        </button>

                        <button
                          onClick={onAddListingClick}
                          className="d-md-none w-100 btn btn-warning text-white py-2 px-5 text-decoration-none"
                          target="_blank"
                        >
                          {t("add your listing")}
                        </button> */}
                      </div>
                    </div>
                  </div>
                </Link>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>

        <SliderDots
          length={dataBanner?.length || 0}
          active={activeIndex}
          onClick={setActiveIndex}
        />
      </div>
    </div>
  );
}

const SliderDots = ({ length, active, onClick }) => {
  return (
    <div className="d-flex gap-2">
      {Array.from({ length }).map((_, index) => (
        <div
          key={index}
          onClick={() => onClick(index)}
          className={`${
            active === index ? "bg-primary" : "bg-secondary"
          } cursor-pointer rounded-2`}
          style={{
            width: "3.125rem",
            height: "0.625rem",
            transition: "background-color 0.3s",
          }}
        ></div>
      ))}
    </div>
  );
};
