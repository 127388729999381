import { gql } from "@apollo/client";

export const BLOG_LIST = gql`
  query getBlogList {
    blog {
      posts {
        id
        post_name
        post_title
        post_date
        url
        post_content
        category
        thumbnail
      }
    }
  }
`;

export const BLOG_PAGE_LIST = gql`
  query getBlogPageList($limit: Int, $page: Int, $category: String) {
    blog {
      posts_pagination(limit: $limit, page: $page, category: $category) {
        total_data
        nodes {
          id
          post_name
          post_title
          post_date
          url
          post_content
          category
          thumbnail
        }
      }
    }
  }
`;

export const BLOG_DETAIL = gql`
  query getBlogDetail($post_name: String!) {
    blog {
      post_by_name(post_name: $post_name) {
        id
        post_name
        post_title
        post_date
        url
        post_content
        thumbnail
        category
      }
    }
  }
`;

export const BLOG_RECOMMENDATION = gql`
  query getBlogRecommendations($limit: Int, $exception_post_id: Int) {
    blog {
      post_recommendations(
        limit: $limit
        exception_post_id: $exception_post_id
      ) {
        post_name
        post_title
        post_date
        url
        post_content
        thumbnail
        category
      }
    }
  }
`;

export const BLOG_TOP_VIEWS = gql`
  query getBlogTopViews($limit: Int, $exception_post_id: Int) {
    blog {
      post_top_views(limit: $limit, exception_post_id: $exception_post_id) {
        post_name
        post_title
        post_date
        url
        post_content
        thumbnail
        category
      }
    }
  }
`;
