import { gql } from "@apollo/client";

export const BANNER_LIST = gql`
  query getBannerList {
    banner {
      banners {
        id
        name
        status
        url
        input_by
        mobile_banner
        media_image {
          path
          default
          downloadable
        }
        type
        object
      }
    }
  }
`;
